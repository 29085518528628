import React, { useState, useEffect } from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import {
  Button,
  Container,
  List,
  ListItem,
  ListItemText,
  Box,
} from "@mui/material";
const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_EMAIL_READER_CLIENT_ID, // Replace with your Azure AD app client ID
    authority:
      "https://login.microsoftonline.com/" +
      process.env.REACT_APP_EMAIL_READER_TENANT_ID, // Replace with your tenant ID
    redirectUri:
      process.env.REACT_APP_PROD === "prod"
        ? process.env.REACT_APP_REDIRECTURI
        : process.env.REACT_APP_DEV_REDIRECTURI,
  },
};

const pca = new PublicClientApplication(msalConfig);

const Attachments = () => {
  const [attachments, setAttachments] = useState([]);
  const formatDate = (date) => {
    const day = `0${date.getDate()}`.slice(-2);
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const year = date.getFullYear();
    return `${day}${month}${year}`;
  };
  const todaysDate = formatDate(new Date());
  const subjectToFind = `Bse_vwapfiles - ${todaysDate} `;
  useEffect(() => {
    pca.initialize().catch((error) => {
      console.error("MSAL initialization error: ", error);
    });
  }, []);

  const login = async () => {
    console.log("trying to log in");
    try {
      const loginResponse = await pca.loginPopup({
        scopes: ["Mail.Read"],
      });

      const accessToken = loginResponse.accessToken;
      fetchAttachments(accessToken);
    } catch (error) {
      console.error("Login error: ", error);
    }
  };
  const fetchAttachments = async (accessToken) => {
    const todaysDate = formatDate(new Date());
    const subjectToFind = `vwapfiles[${todaysDate}]`;
    console.log("looking for ", subjectToFind);

    try {
      const response = await fetch(
        "https://graph.microsoft.com/v1.0/me/messages",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const data = await response.json();
      const messages = data.value;
      const targetMessage = messages.find((message) =>
        message.subject.toLowerCase().includes(subjectToFind.toLowerCase())
      );

      if (targetMessage) {
        const attachmentsResponse = await fetch(
          `https://graph.microsoft.com/v1.0/me/messages/${targetMessage.id}/attachments`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        const attachmentsData = await attachmentsResponse.json();
        setAttachments(attachmentsData.value);
      } else {
        console.log("No message found with the specified subject");
      }
    } catch (error) {
      console.error("Error fetching attachments: ", error);
    }
  };
  const uploadAttachment = async (attachment) => {
    const byteCharacters = atob(attachment.contentBytes);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const file = new Blob([byteArray], { type: attachment.contentType });

    const formData = new FormData();
    formData.append("file", file, attachment.name);

    try {
      const response = await fetch(
        process.env.REACT_APP_INTERNALBACKEND_API_URL + "/upload",
        {
          method: "POST",
          body: formData,
          mode: "cors", // Ensure CORS mode is set
        }
      );

      const result = await response.json();
      console.log(result.message);
    } catch (error) {
      console.error("Error uploading attachment: ", error);
    }
  };

  return (
    <Container>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: { xs: "flex-start", md: "center" },
          width: "100%",
        }}
      >
        <Box sx={{ flex: 1 }}>
          <ListItemText
            primary="Download BANKEX & SENSEX SPI"
            secondary="Provided by ShareIndia through Mail"
          />
        </Box>
        <Button
          onClick={login}
          sx={{ mt: { xs: 2, md: 0 }, ml: { md: 2 } }} // Adjust margin for spacing
        >
          Login and Fetch Attachments
        </Button>
      </Box>
      <List>
        {attachments.map((attachment) => (
          <ListItem key={attachment.id}>
            {subjectToFind}
            {" - "}
            {attachment.name}{" "}
            <Button onClick={() => uploadAttachment(attachment)}>Upload</Button>
          </ListItem>
        ))}
      </List>
    </Container>
  );
};

export default Attachments;
