import React, { useState, useEffect } from "react";
import { Container, Grid, Paper, Typography, Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import PlotContainer from "./PlotContainerNew";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function DynamicGrid() {
  const query = useQuery();
  const framesParam = query.get("frames");
  const [frames, setFrames] = useState(parseInt(framesParam) || 2);

  useEffect(() => {
    setFrames(parseInt(framesParam) || 2);
  }, [framesParam]);

  return (
    <Container maxWidth={false}>
      <Box mt={1}>
        {" "}
        {/* Adds top padding */}
        <Grid container spacing={0}>
          {Array.from({ length: frames }, (_, index) => (
            <Grid item xs={12} sm={12} md={12} lg={6} key={index}>
              <Paper elevation={3} style={{ padding: 6, textAlign: "center" }}>
                <PlotContainer quadrant={index} />
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
}

export default DynamicGrid;
