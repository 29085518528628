import React from "react";
// import DataFrameDisplay from './OptionChain'
import Navbar from "./Components/NavBar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Reports from "./Components/Reports";
import DashboardSummary from "./Components/StrategyDashboard/DashboardSummary/DashboardSummary";
// import AccountSummary from "./Components/AccountSummary";
import DetailedStrategySummary from "./Components/StrategyDashboard/DetailedStrategySummary/DetailedStrategySummary";
import Notifications from "./Components/Notifications";
import { WebSocketProvider } from "./Components/NotificationsWebSocketContext";
// import "./App.css";
import SignOut from "./Components/SignOut";
import RequireAuth from "./RequireAuth";
import OpsView from "./Components/OpsDashboard/OpsView";
import Plotter from "./Components/Plotter/PlotterNew";
import PlotterRedirect from "./Components/Plotter/PlotterRedirect"; // Import the redirect component

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
const msalInstance = new PublicClientApplication(msalConfig);

const getDashboardPage = (isMobile) => {
  return (
    <WebSocketProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navbar />}>
            <Route index element={<DashboardSummary isMobile={isMobile} />} />
            <Route
              path="/plotter"
              element={
                <>
                  <PlotterRedirect />
                  <Plotter />
                </>
              }
            />
            {/* <Route path="plotter/:plotNumbers" element={<Plotter />} /> */}
            {/* <Route path="reports" element={<Reports />} /> */}
            <Route path="ops" element={<OpsView />} />
            <Route path="notifications" element={<Notifications />} />
            <Route
              path="trading-summary"
              element={<DashboardSummary isMobile={isMobile} />}
            />
            {/* <Route path="sign-out" element={<SignOut />} /> */}
            <Route
              path="detailed-strategy-summary"
              element={<DetailedStrategySummary isMobile={isMobile} />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </WebSocketProvider>
  );
};

function App() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  console.log(process.env.REACT_APP_PROD);
  if (process.env.REACT_APP_PROD === "prod") {
    return (
      <MsalProvider instance={msalInstance}>
        <RequireAuth>{getDashboardPage(isMobile)}</RequireAuth>
      </MsalProvider>
    );
  } else {
    return getDashboardPage(isMobile);
  }
}

export default App;
