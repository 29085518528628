import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function PlotterRedirect() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (!params.has("frames")) {
      params.set("frames", "4");
      navigate(`${location.pathname}?${params.toString()}`, { replace: true });
    }
  }, [location, navigate]);

  return null; // This component can be included within Plotter as a redirect handler
}

export default PlotterRedirect;
