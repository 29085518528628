import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Box,
  Typography,
  Autocomplete,
  Grid,
} from "@mui/material";
import { usePlotterFilterStore } from "../../Store/PlotterFilterStore";
import Tracer from "./Tracer";

const PlotContainer = ({ quadrant }) => {
  const [plotData, setPlotData] = useState(null);
  const [error, setError] = useState(null);
  const [options, setOptions] = useState([]); // Change to array of objects
  const [dataPoints, setDataPoints] = useState(""); // State for X data points
  const [isMaximized, setIsMaximized] = useState(false); // State to track maximization
  const [isFetching, setFetching] = useState(false);

  const filters = usePlotterFilterStore(
    (state) => state.filters[quadrant] || {}
  );
  const setFilter = usePlotterFilterStore((state) => state.setFilter);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const response = await fetch(
          `https://backend.alphextra.com/getPlotterOptions`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch options");
        }
        const data = await response.json();
        const formattedOptions = data.map((option) => ({
          broker: option.broker,
          id: option.id,
          bucket: option.bucket,
        }));
        setOptions(formattedOptions);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchOptions();
  }, []);

  useEffect(() => {
    if (filters.broker && filters.id && filters.bucket && !isFetching) {
      const fetchData = async () => {
        if (isFetching) return null;
        const primaryKey = `${filters.broker}@${filters.id}@${filters.bucket}`;
        try {
          setFetching(true);
          const response = await fetch(
            `https://backend.alphextra.com/getPlotterData?primaryKey=${primaryKey}`
          );
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const data = await response.json();
          setPlotData(data.data);
        } catch (err) {
          setError(err.message);
        } finally {
          setFetching(false);
        }
      };

      // Fetch data initially and every 3 seconds
      fetchData();
      const intervalId = setInterval(fetchData, 2000);

      return () => clearInterval(intervalId);
    } else {
      setPlotData(null); // Clear plot data if filters are incomplete
    }
  }, [filters]);

  if (error) {
    return <div>Error: {error}</div>;
  }
  if (!options.length) {
    return <div>Loading...</div>;
  }

  const handleDoubleClick = () => {
    setIsMaximized(!isMaximized);
  };

  const handleDataPointsChange = (event) => {
    const value = event.target.value;
    if (/^-?\d*$/.test(value)) {
      // Allows optional negative sign and digits
      setDataPoints(value);
    }
  };

  return (
    <Box width="100%">
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={2}>
          <FormControl fullWidth variant="outlined" size="small">
            <InputLabel>Broker</InputLabel>
            <Select
              sx={{ height: "30px" }}
              value={filters.broker || ""}
              onChange={(e) => setFilter(quadrant, "broker", e.target.value)}
              label="Broker"
            >
              {options
                .map((option) => option.broker)
                .filter((value, index, self) => self.indexOf(value) === index) // Unique brokers
                .map((brokerOption, index) => (
                  <MenuItem key={index} value={brokerOption}>
                    {brokerOption}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={1}>
          <FormControl fullWidth variant="outlined" size="small">
            <InputLabel>ID</InputLabel>
            <Select
              sx={{ height: "30px" }}
              value={filters.id || ""}
              onChange={(e) => setFilter(quadrant, "id", e.target.value)}
              label="ID"
            >
              {options
                .filter((option) => option.broker === filters.broker) // Filter by selected broker
                .map((option) => option.id)
                .filter((value, index, self) => self.indexOf(value) === index) // Unique IDs
                .map((idOption, index) => (
                  <MenuItem key={index} value={idOption}>
                    {idOption}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={3}>
          {/* <FormControl fullWidth variant="outlined" size="small">
            <InputLabel>Bucket</InputLabel>
            <Select
              sx={{ height: "30px" }}
              value={filters.bucket || ""}
              onChange={(e) => setFilter(quadrant, "bucket", e.target.value)}
              label="Bucket"
            >
              {options
                .filter(
                  (option) =>
                    option.broker === filters.broker && option.id === filters.id
                ) // Filter by selected broker and ID
                .map((option) => option.bucket)
                .filter((value, index, self) => self.indexOf(value) === index) // Unique buckets
                .map((bucketOption, index) => (
                  <MenuItem key={index} value={bucketOption}>
                    {bucketOption}
                  </MenuItem>
                ))}
            </Select>
          </FormControl> */}
          <FormControl fullWidth variant="outlined" size="small">
            <Autocomplete
              options={[
                ...new Set(
                  options
                    .filter(
                      (option) =>
                        option.broker === filters.broker &&
                        option.id === filters.id
                    )
                    .map((option) => option.bucket)
                ),
              ]} // Unique buckets based on selected broker and ID
              value={filters.bucket || ""}
              onChange={(event, newValue) =>
                setFilter(quadrant, "bucket", newValue)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Bucket"
                  variant="outlined"
                  size="small"
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={3}>
          <TextField
            fullWidth
            label="Last X Data Points"
            variant="outlined"
            value={dataPoints}
            onChange={handleDataPointsChange}
            type="number"
            size="small"
          />
        </Grid>
      </Grid>
      {plotData ? (
        <Tracer data={plotData} quadrant={quadrant} dataPoints={dataPoints} />
      ) : (
        <Typography variant="body1" color="textSecondary">
          Please set all filters to view the plot.
        </Typography>
      )}
    </Box>
  );
};

export default PlotContainer;
